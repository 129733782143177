import { BasicStyledComponent, DSTeamMemberShort } from 'shared-definitions/types'
import styles from './PostedBy.module.css'
import stylesCommon from './PostedBy.PostedBySponsor.module.css'
import clsx from 'clsx'
import CommentsCount from './CommentsCount'

interface PostedByProps extends BasicStyledComponent {
  engagementContainerId: string | null
  author: DSTeamMemberShort
  date?: string
  dateClassName?: string
}

const PostedBy: React.VFC<PostedByProps> = ({
  author,
  date,
  dateClassName,
  className,
  engagementContainerId,
}) => (
  <div className={clsx(stylesCommon.container, styles.whiteStyle, className)}>
    {author.name}
    {date ? (
      <span className={clsx(styles.prefix, stylesCommon.grayStyle, dateClassName)}>{date}</span>
    ) : null}
    {engagementContainerId && (
      <CommentsCount
        size="small"
        className={clsx(styles.prefix, styles.counter)}
        containerId={engagementContainerId}
      />
    )}
  </div>
)

export default PostedBy
