import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'
import clsx from 'clsx'
import PostedBy from 'shared-components/posts/PostedBy'
import PostedBySponsor from 'shared-components/posts/PostedBySponsor'

interface PostedByContainerProps extends BasicStyledComponent {
  dateClassName?: string
  post: Pick<DSPostShortWithAuthor, 'author' | 'sponsor' | 'date' | 'engagementContainerId'>
}

const PostedByContainer: React.VFC<PostedByContainerProps> = ({
  className,
  dateClassName,
  post: { author, date, sponsor, engagementContainerId },
}) => {
  if (author && !sponsor) {
    return (
      <PostedBy
        engagementContainerId={engagementContainerId}
        dateClassName={dateClassName}
        className={className}
        author={author}
        date={date}
      />
    )
  }

  if (sponsor) {
    return <PostedBySponsor sponsor={sponsor} className={clsx(className, dateClassName)} />
  }

  return null
}

export default PostedByContainer
